/* CUSTOMIZE THE NAVBAR
-------------------------------------------------- */

/* Special class on .container surrounding .navbar, used for positioning it into place. */
.navbar-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
}

/* Flip around the padding for proper display in narrow viewports */
.navbar-wrapper > .container {
  padding-right: 0;
  padding-left: 0;
}
.navbar-wrapper .navbar {
  padding-right: 15px;
  padding-left: 15px;
}
.navbar-wrapper .navbar .container {
  width: auto;
}


/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  // height: 1000px;
  // height: 200px;
  height: 100%;
  margin-bottom: 20px;
}

.container {
  z-index: 900;
}

/* Since positioning the image, we need to help out the caption */
.caption {
  z-index: 1000 !important;
  text-shadow: 1px 1px 1px rgba(0,0,0,.8);
  text-align: left !important;
  left: 12% !important;
  margin-top: 10rem;
  color: #fff;

  h1 {
    font-weight: bold;
    font-size: 6.875rem;
    z-index: 1000;
    margin: 0;
    padding: 0;
  }

  p {
    padding-top: 3em;
    font-size: 2.5em;
  }

  a {
    background: transparent;
    border: 2px solid #fff;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    line-height: 1;
    color: #fff;
    border-radius: .625rem 0 .625rem 0;
    padding: 15px 30px;
    margin-left: 2rem;
  }

  a:hover {
    background: #fff;
    border: 2px solid #fff;
    color: #3f4754;
    text-decoration: none;
  }

  a:first-child {
    margin-left: 0;
  }

  @media (max-width: 998px) {
    h1 {
      font-size: 2.5em;
    }
    p {
      font-size: 1em;
    }
  }
}

/* Declare heights because of positioning of img element */
.carousel .item {
  height: 100%;
  // background-color: #777;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 45%;
}
// .carousel-inner > .item > img {
//   position:relative;
//   top:50%;
//   // transform: translateY(-35%);
//   min-width: 100%;
//   height: auto;
// }

.slider-flex {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 1px;
  padding: 5em 15px !important;
  background-color: transparent !important;
  z-index: 500;
}

.carousel-control.left .carousel-control.right {
  background-image:  none;
  filter: none;
}

.item-overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: transparent;
  background-image: linear-gradient(180deg, #d36900 11%, rgba(227,53,0,0) 50%);
  opacity: 0.66;
  transition: background( 0.3s, border-radius 0.3s, opacity 0.3s);
  z-index: 100;
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 20px;
  text-align: center;
}
.marketing h2 {
  font-weight: normal;
}
.marketing .col-lg-4 p {
  margin-right: 10px;
  margin-left: 10px;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 80px 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -1px;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 768px) {
  /* Navbar positioning foo */
  .navbar-wrapper {
    margin-top: 20px;
  }
  .navbar-wrapper .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .navbar-wrapper .navbar {
    padding-right: 0;
    padding-left: 0;
  }

  /* The navbar becomes detached from the top, so we round the corners */
  .navbar-wrapper .navbar {
    border-radius: 4px;
  }

  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 20px;
    font-size: 21px;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }

  .carousel, .carousel .item {
    height: auto;
    max-height: 770px;
  }
}

// @media (min-width: 768px) {
//   .carousel, .carousel .item {
//     height: 300px;
//   }
// }

// @media (min-width: 992px) {
//   .featurette-heading {
//     margin-top: 120px;
//   }
//   .carousel, .carousel .item {
//     height: 350px;
//   }
// }

// @media (min-width: 1150px) {
//   .carousel, .carousel .item {
//     height: 450px;
//   }
// }

@media (max-width: 48em) {
  .carousel-caption {
    top: 3em;

    h1 {
      font-size: 3rem;
    }

    p {
      padding: 0;
    }

    a {
      margin-left: 0;
      margin-bottom: .5em;
      width: 50%;
      padding: 0.5em 0.5em;
    }
  }
}