.upload {
  min-height: auto;
  border: 3px dashed #CCC !important;
  border-radius: 15px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin-top: 20px;
  padding: 20px;
}
.dropzone
{
  min-height: auto;
  border: 1px solid #e7eaec !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.dropzone .dropzone-previews { position: relative; }

.dropzone .dz-sort-help { display: block; margin: 0 0 10px 0; }
.dropzone .dz-progress-bar { margin-bottom: 15px; }
.dropzone .dz-progress-bar .progress { margin-bottom: 2px; }
.dropzone .dz-message {
  cursor: pointer;
  margin: 0;
  padding: 2em;
  border: 1px dashed #e7eaec;
  border-radius: 10px;
}
.dropzone.dz-started .dz-message { display: block; }

.dropzone.dz-drag-hover .dz-message, .dropzone .dz-message:hover {
  background-color: #e7eaec;
  opacity: 1;
}
.dropzone .dz-preview .dz-image {
  width: 118px;
}
.dropzone .dz-preview {
  margin: 0 20px 20px 0;
  background-color: transparent;
  width: 120px;
}
.dropzone .dz-preview[data-file_name="contract"],
.dropzone .dz-preview[data-file_name="jof"] {
  border: 1px solid #e7eaec;
}
.dropzone .dz-preview.dz-image-preview { background-color: transparent; }

.dropzone .dz-preview.ui-sortable-placeholder
{
  background: #e7eaec;
  border: 1px dashed #e7eaec;
  border-radius: 10px;
}

.dropzone .dz-preview:hover .dz-image img { -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none; transform: none; -webkit-filter: none; filter: none; }

.dropzone .dz-preview.dz-error:hover .dz-error-message
{
  opacity: 0;
  pointer-events: none;
}

.dropzone .dz-preview.dz-waiting .dz-waiting-mark
{
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-complete .dz-waiting-mark
{
  opacity: 0;
  -webkit-animation: slide-out 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-out 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-out 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-out 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-out 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-error.dz-preview-hover .dz-error-message
{
  opacity: 1;
  pointer-events: auto;
}

.dropzone .dz-preview a { cursor: default; }
.dropzone .dz-preview a[href] { cursor: pointer; }
.dropzone .dz-preview .dz-progress
{
  border: 0;
  background: rgba(255, 255, 255, 0.5);
}
.dropzone .dz-preview .dz-progress .dz-upload { background: #fff; }

.dropzone .dz-preview .dz-image
{
  background-color: #fff;
  border-radius: 10px;
}
.dropzone .dz-preview .dz-image img { width: 100%; }
.dropzone .dz-preview .dz-image i
{
  position: absolute;
  margin: -25px 0 0 -25px;
  top: 50%;
  left: 50%;
  width: 51px;
  height: 51px;
  font-size: 51px;
  line-height: 51px;
  text-align: center;
  color: #696969;
}
.dropzone .dz-preview .dz-image i.fa-file-pdf-o { color: #B10302; }
.dropzone .dz-preview .dz-image i.fa-file-word-o { color: #D24726; }
.dropzone .dz-preview .dz-image i.fa-file-excel-o { color: #207245; }
.dropzone .dz-preview .dz-image i.fa-file-powerpoint-o { color: #D24726; }
.dropzone .dz-preview .dz-image i.fa-file-archive-o { color: #696969; }

.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-waiting-mark
{
  pointer-events: none;
  display: block;
  position: absolute;
  margin: -25px 0 0 -25px;
  top: 50%;
  left: 50%;
  width: 51px;
  height: 51px;
  opacity: 0;
  z-index: 500;
}
.dropzone .dz-preview .dz-success-mark i,
.dropzone .dz-preview .dz-error-mark i,
.dropzone .dz-preview .dz-waiting-mark i
{
  margin: -25px 0 0 -25px;
  color: #fff;
  text-shadow: 0 0 3px rgba(0,0,0,.25);
}

.dropzone .dz-preview .dz-waiting-mark i
{
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  transform-origin: 25px 25px;
}

.dropzone .dz-preview .dz-details
{
  position: relative;
  padding: 8px 0 0 0;
  line-height: 1.2;
  opacity: 1;
}
.dropzone .dz-preview .dz-details .dz-filename,
.dropzone .dz-preview .dz-details .dz-size {
  display: block;
  margin: 0;
  font-size: inherit;
}
.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
  background: transparent;
  border-radius: 0;
}
.dropzone .dz-preview .dz-details .dz-filename:hover span { border: 0; }
.dropzone .dz-preview .dz-details .dz-size strong { font-weight: normal; }
.dropzone .dz-preview .dz-details span { padding: 0 !important; }

.dropzone .dz-preview .dz-options
{
  display: none;
  position: absolute;
  padding: 5px;
  top: 5px;
  right: 5px;
  color: #000;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, .25);
  z-index: 100;
}

.dropzone .dz-preview .dz-icons
{
  display: none;
  position: absolute;
  top: 5px;
  left: 5px;
  color: #000;
  z-index: 100;
}
.dropzone .dz-preview .dz-icons i {
  padding: 5px;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, .25);
}

.dropzone .dz-preview.dz-success .dz-options,
.dropzone .dz-preview.dz-success .dz-icons { display: block; }

.dropzone .dz-preview.dropdown.open a.dz-image,
.dropzone .dz-preview.dropdown.open a.dz-filename { pointer-events: none; }

.dropzone .dz-preview .dropdown-toggle { cursor: pointer; }
.dropzone .dz-preview .dropdown-menu {
    top: 35px;
    left: 6px;
    right: 6px;
    min-width: auto;
}
.dropzone .dz-preview .dropdown-menu li { padding: 0 4px; }
.dropzone .dz-preview .dropdown-menu li:first-child { padding-top: 4px; }
.dropzone .dz-preview .dropdown-menu li:last-child { padding-bottom: 4px; }
.dropzone .dz-preview .dropdown-menu a { cursor: pointer; margin: 0; padding: 0 5px; line-height: 23px; }
.dropzone .dz-preview .dropdown-menu i { margin-right: 5px; }
.dropzone .dz-preview .dropdown-menu .divider { margin: 0 }
.dropzone .dz-preview .dropdown-menu:before, .dropzone .dz-preview .dropdown-menu:after
{
  display: inline-block;
  position: absolute;
  content: '';
}

.dropzone .dz-preview .dropdown-menu:before
{
  top: -7px;
  right: 3px;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.dropzone .dz-preview .dropdown-menu:after
{
  top: -6px;
  right: 4px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: '';
}
.dropzone.dz-single .dropzone-previews { text-align: center; }
.dropzone.dz-single .dz-preview { margin: 0 0 20px 0; }

.dz-preview.ui-sortable-helper .dz-image {
  background-color: #fff;
  border-radius: 10px;
  width: 118px;
  height: 120px;
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1049;
}
.dz-preview.ui-sortable-helper .dz-image img { width: 100%; }
.dz-preview.ui-sortable-helper .dz-image i {
  position: absolute;
  margin: -25px 0 0 -25px;
  top: 50%;
  left: 50%;
  width: 51px;
  height: 51px;
  font-size: 51px;
  line-height: 51px;
  text-align: center;
  color: #696969;
}
.dz-preview.ui-sortable-helper .dz-image i.fa-file-pdf-o { color: #B10302; }
.dz-preview.ui-sortable-helper .dz-image i.fa-file-word-o { color: #D24726; }
.dz-preview.ui-sortable-helper .dz-image i.fa-file-excel-o { color: #207245; }
.dz-preview.ui-sortable-helper .dz-image i.fa-file-powerpoint-o { color: #D24726; }
.dz-preview.ui-sortable-helper .dz-image i.fa-file-archive-o { color: #696969; }
.dz-preview.ui-sortable-helper .dz-options {
  position: absolute;
  padding: 5px;
  top: 5px;
  right: 5px;
  color: #000;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, .25);
  z-index: 1050;
}
.dz-preview.ui-sortable-helper .dz-icons {
  position: absolute;
  top: 5px;
  left: 5px;
  color: #000;
  z-index: 1050;
}
.dz-preview.ui-sortable-helper .dz-icons i {
  padding: 5px;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, .25);
}
.dz-preview.ui-sortable-helper .dz-details {
  z-index: 1049;
  position: relative;
  padding: 8px 0 0 0;
  line-height: 1.2;
  opacity: 1;
  top: 0;
  left: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
}
